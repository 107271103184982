<template>
  <div class="page-container stock-dashboard-page">
    <portal to="page-name">Dashboard</portal>
    <div class="dashboard-tiles">
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'box']"></fa-icon>
        </div>
        <div class="tile-label">Quantidade de produtos</div>
        <div class="tile-value">0</div>
      </div>
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'medkit']"></fa-icon>
        </div>
        <div class="tile-label">Quantidade de kits</div>
        <div class="tile-value">0</div>
      </div>
      <div class="tile-item c-hand">
        <div class="tile-icon text-secondary">
          <fa-icon :icon="['fal', 'sack-dollar']"></fa-icon>
        </div>
        <div class="tile-label">Valor do estoque</div>
        <div class="tile-value">R$0,00</div>
      </div>
    </div>
    <div class="card chart-card">
      <div class="card-body">
        <div class="empty" v-if="charts.transaction.data.datasets.length === 0">
          <div class="empty-icon">
            <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
          </div>
          <div class="empty-title h6">Movimentação mensal</div>
          <div class="empty-subtitle">
            <slot>Nenhuma movimentação registrada</slot>
          </div>
        </div>
        <template v-else>
          <div class="card-title">Movimentação mensal por valor</div>
          <st-line-chart
            :chart-data="charts.transaction.data"
            :options="charts.transaction.options"
            :height="245"
          />
        </template>
      </div>
    </div>
    <div class="columns">
      <div class="column col-4">
        <div class="card patient-card">
          <div class="card-body">
            <div class="empty" v-if="stats.minimum.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-title h6">Abaixo do mínimo</div>
              <div class="empty-subtitle">
                <slot>Nenhum produto abaixo do mínimo</slot>
              </div>
            </div>
            <template v-else>
              <div class="card-title">Produtos abaixo do mínimo</div>
              <div class="scroll-list-wrapper">
                <div class="scroll-list">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Produto</th>
                      <th class="text-center">Quantidade</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in 0" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td class="text-center">{{ item.quantity | number }}</td>
                      <td class="text-right">
                        <button class="btn btn-action btn-icon btn-gray btn-sm">
                          <fa-icon :icon="['fal', 'search']"/>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="column col-4">
        <div class="card patient-card">
          <div class="card-body">
            <div class="empty" v-if="stats.minimum.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-title h6">Ponto de pedido</div>
              <div class="empty-subtitle">
                <slot>Nenhum produto para compra</slot>
              </div>
            </div>
            <template v-else>
              <div class="card-title">Produtos para compra</div>
              <div class="scroll-list-wrapper">
                <div class="scroll-list">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Produto</th>
                      <th class="text-center">Quantidade</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in 0" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td class="text-center">{{ item.quantity | number }}</td>
                      <td class="text-right">
                        <button class="btn btn-action btn-icon btn-gray btn-sm">
                          <fa-icon :icon="['fal', 'search']"/>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="column col-4">
        <div class="card patient-card">
          <div class="card-body">
            <div class="empty" v-if="stats.minimum.length === 0">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
              </div>
              <div class="empty-title h6">Vencidos</div>
              <div class="empty-subtitle">
                <slot>Nenhum produto vencido</slot>
              </div>
            </div>
            <template v-else>
              <div class="card-title">Produtos vencidos</div>
              <div class="scroll-list-wrapper">
                <div class="scroll-list">
                  <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>Produto</th>
                      <th>Lote</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, i) in 0" :key="item.id">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.lot.name }}</td>
                      <td class="text-right">
                        <button class="btn btn-action btn-icon btn-gray btn-sm">
                          <fa-icon :icon="['fal', 'search']"/>
                        </button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      stats: {
        minimum: [],
      },
      charts: {
        transaction: {
          data: {
            labels: [],
            datasets: [],
            // labels: [
            //   '10/2020',
            //   '11/2020',
            //   '12/2020',
            //   '01/2021',
            //   '02/2021',
            //   '03/2021',
            //   '04/2021',
            //   '05/2021',
            //   '06/2021',
            //   '07/2021',
            //   '08/2021',
            //   '09/2021',
            // ],
            // datasets: [{
            //   label: 'Valor',
            //   data: [
            //     15000,
            //     18000,
            //     16500,
            //     19300,
            //     20400,
            //     22600,
            //     22900,
            //     24100,
            //     26800,
            //     28000,
            //     26900,
            //     31500,
            //   ],
            //   backgroundColor: [
            //     'rgba(255, 206, 86, 0.2)',
            //   ],
            //   borderColor: [
            //     'rgba(255, 206, 86, 1)',
            //   ],
            //   borderWidth: 1,
            // }],
          },
          options: {
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
              xAxes: [{
                ticks: {
                  callback: value => value,
                },
                stacked: true,
              }],
            },
          },
        },
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  @import "./src/assets/scss/mixins";
.stock-dashboard-page {
  .dashboard-tiles {
    display: flex;
    padding: 0 0 $layout-spacing-lg 0;
    color: $gray-color-dark;
    .tile-item {
      background-color: $light-color;
      border: $border-width solid $border-color;
      border-radius: $border-radius;
      flex: 1;
      margin-left: $layout-spacing-lg;
      padding: 20px;
      position: relative;
      text-align: center;
      &:first-child {
        margin-left: 0;
      }
    }
    .tile-icon {
      margin-bottom: $layout-spacing;
      svg {
        display: block;
        height: 1.2rem;
        margin: 0 auto;
        width: auto;
      }
    }
    .tile-label {
      font-size: $font-size-sm;
      line-height: $font-size-sm;
      margin: $layout-spacing-sm 0 calc($layout-spacing-sm / 2) 0;
    }
    .tile-value {
      color: $gray-color-dark;
      font-size: $font-size-lg;
      font-weight: 600;
      line-height: $font-size;
      margin-top: $layout-spacing;
    }
  }
  .chart-card {
    background-color: $light-color;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    .card-title {
      font-size: $font-size-lg;
      text-align: center;
    }
  }

  .patient-card {
    background-color: $light-color;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-top: $layout-spacing-lg;
    .card-title {
      font-size: $font-size-lg;
      text-align: center;
    }
  }

  .scroll-list-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .scroll-list {
    bottom: 0;
    overflow-y: auto;
    top: 0;
    width: 100%;
    height: 256px;
    @include scroll-bar();
  }
}
</style>
