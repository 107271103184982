<template>
  <st-side-page class="stock-page">
    <portal to="page-name">Outros recursos</portal>
    <template slot="side">
      <ul class="menu">
        <li class="menu-item" v-for="(menu, i) in menus" :key="i">
          <router-link :to="menu.path">{{ menu.label }}</router-link>
          <fa-icon :icon="['fal', 'chevron-right']"/>
        </li>
      </ul>
    </template>
    <router-view/>
  </st-side-page>
</template>

<script>
export default {
  data() {
    return {
      menus: [
        { path: '/clinical/procedures', label: 'Produtos' },
        // { path: '/stock/quotations', label: 'Cotações' },
        { path: '/stock/inventories', label: 'Inventários' },
        // { path: '/stock/kits', label: 'Kits' },
        { path: '/stock/price-tables', label: 'Tabela de preços' },
        { path: '/stock/product-groups', label: 'Grupos' },
        { path: '/stock/product-families', label: 'Família' },
        { path: '/providers', label: 'Fornecedores' },
        { path: '/stock/operation-types', label: 'Tipos de Operação' },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .stock-page {
    &.side-page {
      .side-page-side {
        flex: 0 0 11rem;
      }
    }
    .menu {
      .router-link-exact-active {
        color: $primary-color;
        & + svg {
          color: $primary-color;
        }
      }
    }
  }
</style>
